// import babelPolyfill from '@babel/polyfill';
// import 'core-js/es6/array';
// import 'core-js/es6/map';
// import 'core-js/fn/array/includes';
import 'core-js/stable';
import AOS from 'aos';
import style from './_scss/main.scss';
import testimonials from './js/testimonials';

// AOS
import 'aos/dist/aos.css';

AOS.init({
  once: true,
});

// Mobile navigation open/close
const menuIcon = document.querySelector('.menu-icon');
const mobileMenu = document.querySelector('.overlay');
const toggleMenuOpen = function() {
  mobileMenu.classList.toggle('overlay-open');
};
menuIcon.addEventListener('click', toggleMenuOpen);
const closeButton = document.querySelector('.close-button');
closeButton.addEventListener('click', toggleMenuOpen);

const navLi = document.querySelectorAll('.nav-mobile li');
const liArray = [...navLi];
liArray[2].addEventListener('click', toggleMenuOpen);

// Testimonials
const testimonialsContainer = document.querySelector('.testimonials');
const numberOfTestimonials = 10;
// randomize testimonials
const shuffledTestimonials = testimonials
  .map(x => ({ x, r: Math.random() }))
  .sort((a, b) => a.r - b.r)
  .map(a => a.x)
  .slice(0, numberOfTestimonials);
if (testimonialsContainer) {
  testimonialsContainer.innerHTML = shuffledTestimonials
    .map(
      tst =>
        `<div class='testimonial'><p>${tst.text}</p><p class="sign">${tst.author} - ${tst.source}</p></div>`
    )
    .join('');
}
