const testimonials = [
  {
    text:
      'Właściciele wspaniali. W pokojach wszystko co potrzeba. W cenie pokoju jest możliwość korzystania z rowerów - super opcja na zwiedzanie i aktywny wypoczynek. Dołączona jest również karta parkingowa na plażę zachodnią - wjazd pod samo wejście na plażę. Z hotelu blisko do głównego deptaka. Duży ogród z atrakcjami dla dzieci i możliwością odpoczynku. Bardzo polecam.',
    author: 'Katarzyna',
    source: 'Booking.com',
  },
  {
    text:
      'Pokój numer 2 (2-3 osobowy). Łózko duże bardzo wygodne. W pokoju wszystko co potrzeba - reczniki, szklanki talerze noze widelce, czajnik. Ogolnodostepne lezaki, parawan, lodowka. W cenie wynajmu mozna wypozyczyc rowery - fantastyczna opcja do zwiedzania Łeby i okolic. Na terenie znajduje sie duzy plac, gdzie dostepne sa atrakcje dla dzieci - hustawki, zjezdzalnie. Dodatkowo lezaki, grill. Prywatne miejsce parkingowe. Wlasciciele bardzo sympatyczni i pomocni. Polecam',
    author: 'Michał',
    source: 'Booking.com',
  },
  {
    text:
      'Właściciel bardzo miły i pomocny. Piękny duży ogród, sporo drzew dających cień i chłód w upalne dni.',
    author: 'Leszek',
    source: 'Booking.com',
  },
  {
    text:
      'Niezwykle przyjemne miejsce! Świetny gospodarz, pomocny, zaangażowany. Wygodne materace. Adekwatny, wygodny, ładny apartament.Czysto. Wspaniały ogród z częścią wypoczynkową, placem zabaw. Łatwy parking.',
    author: 'Justyna',
    source: 'Booking.com',
  },
  {
    text:
      'Bardzo ładne pokoje, z pełnym wyposażeniem. Blisko do centrum, wszędzie można chodzić pieszo. Idealne miejsce dla rodzin z dziećmi. Polecam',
    author: 'Dorota',
    source: 'Booking.com',
  },
  {
    text:
      'Świetne miejsce, nowocześnie urządzone pokoje, czysto, w pełni wyposażone, bardzo miła i kontaktowa obsługa. Równie dobra lokalizacja - blisko do głównego deptaka, a jednocześnie trochę ciszej niż na głównej ulicy. Zdecydowanie polecam!',
    author: 'Mateusz',
    source: 'Booking.com',
  },
  {
    text:
      'Lokalizacja fajna - blisko centrum a zarazem z dala od zgiełku. Gospodarz bardzo pomocny i sympatyczny. Napewno wrócimy.',
    author: 'Agata',
    source: 'Booking.com',
  },
  {
    text:
      'Bardzo miła obsługa. Spokojna okolica, cisza, przyjemny ogród z hamakami, do tego dobra lokalizacja. Wygodne łóżko i gustowne pokoje, dostaliśmy większy pokój niż zarezerwowaliśmy. Wszystko profesjonalnie zorganizowane. Do tego możliwość skorzystania z rowerów żeby zwiedzić np. okoliczne wydmy. Jedno z najlepszych miejsc w Łebie.',
    author: 'Ciacho',
    source: 'Booking.com',
  },
  {
    text:
      'Polecam bardzo serdecznie! Wszystko zgodne ze zdjęciami i opisem 😊 Miła obsługa, bardzo ładne pokoje, czyściutko, możliwość wypożyczenia rowerów jak i innych sprzętów, lokalizacja obiektu blisko centrum. Gorąco polecam! 😊',
    author: 'Oliwia',
    source: 'Facebook',
  },
  {
    text:
      'Dobrze, że są takie miejsca! Niczego nam nie brakowało, świetnie urządzony dom, ogród rewelacja! Dzieci zachwycone, Janek, mój wnuk, już wyraził swoją opinię: najlepszy dom, w jakim byliśmy.I świetna lokalizacja, w sercu Łeby, ale spokój i cisza. Obsługa bardzo życzliwa i pomocna, no i świetne rowery! Jednym słowem: JECHAĆ!',
    author: 'Hanka',
    source: 'Facebook',
  },
  {
    text: 'Niesamowite miejsce w pięknym mieście z niesamowitą obsługą!',
    author: 'Armando',
    source: 'Facebook',
  },
  {
    text:
      'Polecam jak nic! Takich standardów ciężko doszukać się na naszym wybrzeżu. Właściciele wykonali tutaj kawał dobrej roboty aby goście mogli skupić się wyłącznie na sobie i wypoczynku. Miła i ciekawa obsługa to tylko jedno z wielu pozytywnych aspektów tego pięknego miejsca. Zdecydowanie tutaj powrócimy!',
    author: 'Kuba',
    source: 'Facebook',
  },
  {
    text:
      'Jak najbardziej polecam. Bardzo piękne miejsce, czysto, spokojnie, i bardzo miły właściciel. Właśnie wybieramy się znowu na piękny tydzień w maju.',
    author: 'Peter',
    source: 'Facebook',
  },
  {
    text:
      'Bardzo fajne miejsce, przepiękny ogród z dużymi drzewami w południe dającymi przyjemny cień. Czysto, schludnie, obiekt zadbany, bardzo mili właściciele. Gorąco polecam wszystkim. Mimo braku klimatyzacji w pokojach nie jest duszno.',
    author: 'Paweł',
    source: 'Google',
  },
  {
    text:
      'Miejsce klimatyczne, z pięknym ogrodem i bardzo miłymi właścicielami. Gorąco polecam rodzinom.',
    author: 'Anna',
    source: 'Google',
  },
  {
    text: 'SUPER',
    author: 'Justyna',
    source: 'Google',
  },
  {
    text:
      'Surf Villa położona jest w cichej i spokojnej okolicy, jednocześnie blisko centrum. Obiekt dysponuje dużym ogrodem z wieloma atrakcjami dla dzieci, m.in.: plac zabaw, piłkarzyki, domek z piaskownicą i ścianą wspinaczkową, slackline(lina do chodzenia i ćwiczenia równowagi), hamaki, rakietki do gier, zabawki dla dzieci. Możliwość bezpłatnego wypożyczenia rowerów. Pokoje czyste, zadbane, nowoczesne w pełni wyposażone.Miła i uprzejma obsługa :). Polecamy, my na pewno tam wrócimy:)',
    author: 'Marcin',
    source: 'TripAdvisor',
  },
];

export default testimonials;
